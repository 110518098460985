@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: #fbfbfb;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

.btn-explore {
    border-radius: 0;
    font-weight: 600;
    border: 2px solid #000;
    background-color: #000;
}

.btn-explore:hover {
    color: #ab0e09;
    border: 1px solid #ab0e09;
}

.btn-services,
.btn-submit {
    background-color: #000;
    color: #fff;
    border-radius: 0;
    font-size: 18px;
}

.btn-services:hover,
.btn-submit:hover {
    background-color: #fff;
    border: 1px solid #ab0e09;
    color: #ab0e09;
}

.btn-submit {
    font-size: 17px;
}

.subtitle {
    font-size: 42px;
}

.about-us,
.services,
.why-us,
.contact {
    padding: 125px 0 75px 0;
}

.contact {
    padding-bottom: 125px !important;
}

/* header */

.navbar li a {
    font-weight: 500;
    color: #000;
    cursor: pointer;
}

.navbar .active {
    color: #ab0e09 !important;
}

.header{
    background-color: #fff;
}

.header-fixed {
    background-color: #fff !important;
    z-index: 123;
    transition: all .2s ease;
}

.header-fixed .navbar li a {
    color: #000;
}

.header-fixed .navbar .active {
    color: #ab0e09 !important;
}

.header i {
    font-size: 28px;
    color: #fff;
}

.header.header-fixed i {
    color: #000;
}

/* banner */

.banner {
    background-color: #efefef;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.banner-bg {
    background-image: url("https://www.openaccessgovernment.org/wp-content/uploads/2022/09/dreamstime_xxl_171995839-scaled.jpg");
    /* background-image: url("../public/images/photo-wall-texture-pattern.jpg"); */
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    /* opacity: ; */
    z-index: 1;
}

.banner h1 {
    color: #fff;
    font-size: 62px;
    font-weight: 600;
}

.banner p {
    color: #fff;
    font-size: 20px;
}

/* about us */

.about-us p,
.why-us p,
.contact p {
    font-size: 18px;
}

/* servies */

.services img {
    height: 300px;
    margin: 0 auto;
}

/* contact */

.contact-card {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
}

.contact-card .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #adadad;
    border-radius: 0;
    color: #262626;
}

.contact-card .form-floating>label {
    color: #adadad;
}

.contact-card .form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
    background-color: transparent;
    color: #fff;
}

.contact-card .form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: #959595;
}

.contact-card .form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    transform: scale(.85) translateY(-1rem) translateX(.15rem);
}

.contact-card .form-control:focus {
    box-shadow: none;
}

/* footer */

.footer {
    background-color: #fbfbfb;
    border-top: 1px solid #efefef;
    padding: 30px 0;
}

.footer p {
    font-size: 14px;
    font-weight: 500;
}

.slick-slide {
    padding: 20px;
}

.text-truncate-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.mobile-navbar {
    font-size: 22px;
}

.mobile-navbar a {
    color: #262626;
}

.mobile-navbar a.active {
    color: #2D62FD;
}

.offcanvas-header .btn-close {
    font-size: 20px;
}

.offcanvas-footer {
    padding: 40px 20px;
    border-top: 1px solid #e9e9e9;
}

.slick-dots li.slick-active button:before {
    color: #ab0e09 !important;
}

.slick-dots li button:before {
    font-size: 16px !important;
}

.scroll-to-top {
    background-color: #ab0e09;
    width: fit-content;
    position: fixed;
    right: 30px;
    bottom: 30px;
    padding: 10px;
    border-radius: 50%;
    display: none;
}

.scroll-to-top.show {
    display: block;
    cursor: pointer;
    transition: all .2s ease;
}

.scroll-to-top.show:hover {
    opacity: .7;
}

@media screen and (max-width: 1200px) {
    .banner h1 {
        font-size: 48px !important;
    }

    .banner-image {
        display: none;
    }

    .why-us-container {
        flex-direction: column-reverse;
    }

    .contact-image {
        display: none;
    }

    .footer-container {
        flex-wrap: wrap;
    }

    .scroll-to-top {
        display: none;
    }
}